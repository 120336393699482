<template>
  <div>
    <div
      v-if="handleSearch"
      class="table-expand-search"
    >
      <b-form-input
        :value="search"
        placeholder="Search..."
        debounce="1500"
        @change="handleSearchChange"
      />
    </div>
    <div
      v-for="(item) in items"
      :key="keyProp ? item[keyProp] : item[keyValue]"
      class="table-expand-card"
      :class="{ 'expanded': expandedItems.includes(`${item[keyValue]}-card`) }"
    >
      <h2
        @click="expand(`${item[keyValue]}-card`)"
      >
        {{ item[keyValue] }}
      </h2>
      <div
        v-for="(field) in fields"
        :key="field.field"
        class="table-expand-card__field"
      >
        <div v-if="field.field === 'action'">
          <div
            class="table-expand-card__field__item__button-container"
          >
            <div
              v-for="(action) in actions"
              :key="action.label"
            >
              <b-button
                v-if="action.visible(item)"
                class="w-100"
                :variant="action.type"
                @click="action.click(item)"
              >
                {{ action.label }}
              </b-button>

            </div>
          </div>
        </div>
        <div
          v-else-if="!field.hidden && (field.field === 'status')"
          class="table-expand-card__field__item"
        >
          <p>Status:
            <b-badge
              pill
              :variant="setStatusColor(item[field.field].id)"
            >
              <span class="text-white">
                {{ item[field.field].name }}
              </span>
            </b-badge>
          </p>
        </div>
        <div
          v-else-if="!field.hidden && (field.field === 'is_online')"
          class="table-expand-card__field__item"
        >
          <p>Status:
            <b-badge
              v-if="item[field.field]"
              pill
              variant="danger"
            >Offline</b-badge>
            <b-badge
              v-else
              pill
              variant="success"
            >Online</b-badge>
          </p>
        </div>
        <div
          v-else-if="!field.hidden"
          class="table-expand-card__field__item"
          :class="{ 'lowercase': field.label.toLowerCase().includes('email') }"
        >
          <p>{{ field.label }}: <span>{{ handleShow(field, item) }}</span></p>
        </div>
      </div>
      <button
        class="table-expand-card__expand"
        :class="{ 'expanded': expandedItems.includes(`${item[keyValue]}-card`) }"
        @click="expand(`${item[keyValue]}-card`)"
      >
        <feather-icon icon="ChevronDownIcon" />
      </button>
    </div>
    <div
      v-if="pagination.enabled"
      class="justify-content-center flex-wrap table-pagination"
    >
      <div class="d-flex align-items-center mb-0 mt-1">
        <span class="text-nowrap">
          Showing 1 to
        </span>
        <b-form-select
          v-model="pagination.perPage"
          :options="pagination.pageSizeOptions"
          class="mx-1"
          @change="pagination.perPageChanged"
        />
        <span class="text-nowrap"> of {{ pagination.pagination.total }} root entries.</span>
      </div>
      <div>
        <b-pagination
          v-if="pagination.pagination.last_page !== 1"
          :value="pagination.currentPage"
          :total-rows="pagination.pagination.total"
          :per-page="pagination.perPage"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="pagination.changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>
<script>

import {
  BFormSelect, BPagination, BButton, BBadge, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BFormSelect,
    BButton,
    BPagination,
    BFormInput,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    handleSearch: {
      type: Function,
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    mobileSearchFields: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({
        enabled: false,
      }),
    },
    actions: {
      type: Array,
      default: () => [],
    },
    pageSizeOptions: {
      type: Array,
      default: () => [10, 25, 50, 100],
    },
    rules: {
      type: Object,
      default: () => {},
    },
    keyValue: {
      type: String,
      default: 'name',
    },
    keyProp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      expandedItems: [],
      searchLocal: '',
    }
  },
  watch: {
    search(newValue) {
      if (newValue === this.searchLocal) {
        return
      }

      this.searchLocal = newValue
    },
  },
  methods: {
    handleShow(field, item) {
      try {
        const key = field.field
        const mobileKey = field.mobileSpecial || null
        if (mobileKey) {
          return this.rules[mobileKey](item)
        }
        const value = item[key]
        if (this.rules && this.rules[key]) {
          return this.rules[key](value)
        }
        if (value === null || value === undefined) {
          return '-'
        }
        return value
      } catch (e) {
        console.error('Error in handleShow', e)
        return '-'
      }
    },
    expand(name) {
      if (this.expandedItems.includes(name)) {
        this.expandedItems = this.expandedItems.filter(item => item !== name)
      } else {
        this.expandedItems.push(name)
      }
    },
    handleSearchChange(searchValue) {
      try {
        const mobileSearchFieldsProp = {
          ...this.mobileSearchFields,
        }
        // for each key set search value
        Object.keys(mobileSearchFieldsProp).forEach(key => {
          mobileSearchFieldsProp[key] = searchValue
        })
        this.handleSearch({ columnFilters: mobileSearchFieldsProp }, searchValue)
      } catch (e) {
        console.error('Error in handleSearchChange', e)
      }
    },
    setStatusColor(statusName) {
      let colorVariant
      switch (statusName) {
        case 'un-allocated':
          colorVariant = 'info'
          break
        case 'allocated':
          colorVariant = 'warning'
          break
        case 'activated':
          colorVariant = 'success'
          break
        case 'renewed':
          colorVariant = 'primary'
          break
        case 'ceasing':
          colorVariant = 'secondary'
          break
        case 'expired':
          colorVariant = 'danger'
          break
        default:
          colorVariant = 'danger'
      }
      return colorVariant
    },
  },

}
</script>

<style lang="scss">
</style>
